import {useLocation, matchPath} from "react-router-dom";
import React, {useEffect} from "react";
import {URLbase, getSection} from "../data/Sections";
import "../components/MySketchFab.css";

class MySketchFab extends React.Component {
    constructor(props) {
      super(props);
      this.iFrame = React.createRef();
      this.Store = false;
    }

    /***************************************
    Camera Functions
    ***************************************/
    goToCamera = (camera) => {
        console.log("goToCamera")
        //let cameraData = camera//(window.innerHeight > window.innerWidth) ? camera.portrait: camera.landscape;
        let cameraData = (window.innerHeight > window.innerWidth) ? camera.portrait: camera.landscape;

        console.log("Camera Data",Object.values(cameraData))
        this.setCamera(Object.values(cameraData)) 
    }
    getCamera = (e) => {
        var target = e.target || e.srcElement;
        var name   = target.innerHTML.trim() 
        // Saves to Clipboard function 
        const copyToClipboard = str => {
          const el = document.createElement('textarea');
          el.value = str;
          el.setAttribute('readonly', '');
          el.style.position = 'absolute';
          el.style.left = '-9999px';
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          console.log("copied:"+str)
        };
        // Gets Camera possition
        this.api.getCameraLookAt(function(err, camera) {
    
          var {position, target} = camera
          position = position.map( n => Number.parseFloat(n).toFixed(4))
          target   = target.map( n => Number.parseFloat(n).toFixed(4))
    
          var cameraArray = name+":[["+position.join()+"],["+target.join()+"], 1],"
          copyToClipboard(cameraArray)
          console.log( cameraArray );
        });
    }
    setCamera = (viewObj) => {
        if(!this.api) return
        this.api.setCameraLookAt(
            viewObj[0], // eye position
            viewObj[1], // target to lookat
            viewObj[3]  // duration of the animation in seconds
        );
    }
    centerCamera = () => this.state.api.recenterCamera( (err) => console.log(err) );
  
    /***************************************
    Viewer Functions
    ***************************************/
    viewerReady = () =>{
        console.log("viewerReady")
        console.log("Location", this.props.location)
        setTimeout(this.goToCamera(this.Store.camera) , 100)
    }
    viewerSuccess = (api) => {
        console.log("viewerSuccess")
        this.api = api
        api.addEventListener('viewerready' ,this.viewerReady);
    }
    viewerError = (err) => console.log("viewerError", err)
    viewerOptions = {
        success: this.viewerSuccess,
        error: this.viewerError,
        //https://sketchfab.com/developers/viewer/initialization
        annotations_visible: 0,
        autostart: 1,
        camera:0,
        ui_animations: 0,
        ui_annotations: 0,
        ui_controls: 0,
        ui_fullscreen: 0,
        ui_general_controls: 0,
        ui_help: 0,
        ui_hint: 0,
        ui_infos: 0,
        ui_inspector: 0,
        ui_inspector_open: 0,
        ui_settings: 0,
        ui_vr: 0,
        ui_watermark_link: 0,
        ui_watermark: 0,
        dof_circle:0,
    }
  
    checkLocation = ()=>{
      let location = useLocation()
      useEffect(
        () => {
          console.log("Sketchfab Location effect", location )
        },
        [location]
      )
    }
    getParams = (pathname) => {
      const matchProfile = matchPath(pathname, {
        path: `${URLbase}/:id/`,
      });
      return (matchProfile && matchProfile.params) || {};
    };
  
    /***************************************
    Component Functions
    ***************************************/
    componentDidMount() {      
        var client = new window.Sketchfab(this.iFrame);
        client.init(this.props.model, this.viewerOptions);
    }
    render() {
        const CameraUX = () =>(
          <div className="cameraUX">
            <button className="btn" onClick={this.getCamera}>portrait</button>
            <button className="btn" onClick={this.getCamera}>landscape</button>
          </div>
        )
        const { pathname }  = this.props.location;
        const currentParams = this.getParams(pathname);
        console.log(currentParams)
        this.Store = getSection(currentParams.id)
        this.goToCamera(this.Store.camera)
        return (
          <>
            <iframe
              className="mySketchFab"
              title="MySketchfab"
              ref={el => this.iFrame = el}
              allowFullScreen
              src=""
              frameBorder="0"
              allow="autoplay; fullscreen; vr"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            ></iframe>
            {this.props.camera && <CameraUX />}
            </>
        );
    }
  }

export default MySketchFab;
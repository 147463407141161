import React, { useRef, useEffect } from "react";
import "../components/MyQuiz.css";

function MyQuiz({ quiz }) {
  const QuizRef = useRef();
  const matchIMG = /\.(?:png|gif|jpe?g|bmp)/i;
  let { questions, results, imgPath, type, className } = quiz;

  useEffect( () => shuffle(QuizRef.current) );

  const shuffle = (quiz) => {
    quiz.querySelectorAll(".questions ol").forEach((el) => {
      for (var i = el.children.length; i >= 0; i--) {
        el.appendChild(el.children[(Math.random() * i) | 0]);
      }
    });
  };
  const removeClass = (cl) => {
    QuizRef.current.querySelectorAll(`.${cl}`).forEach((el) => {
      el.classList.remove(cl);
      el.removeAttribute("data-picked");
    });
  };
  const resetQuiz = () => {
    removeClass("answered");
    removeClass("active");
    QuizRef.current.querySelectorAll(`[data-picked]`).forEach((el) => {
      el.removeAttribute("data-picked");
    });
    //shuffle(QuizRef.current)
    const Q1 = QuizRef.current.querySelector(".Q:first-child");
    Q1.classList.add("active");
  };
  const answerChosen = (e) => {
    e.currentTarget.dataset.picked = "true";

    let p = e.currentTarget.closest(".active");
    p.classList.remove("active");
    p.classList.add("answered");

    p.nextSibling ? p.nextSibling.classList.add("active") : showResults();
  };
  const showResults = () => {
    console.log("showResults");
    //Grab "Picked" Choices
    let picked = [];
    QuizRef.current.querySelectorAll(`[data-picked]`).forEach((el) => {
      picked.push(el.dataset);
    });
    let resultNum = 1;
    // Get results depending on
    switch (type) {
      case "RightWrong":
        const right = picked.filter((v) => v.choice === "0").length;
        QuizRef.current
          .querySelectorAll(`.correct`)
          .forEach((el) => (el.innerHTML = right));
        QuizRef.current
          .querySelectorAll(`.total`)
          .forEach((el) => (el.innerHTML = picked.length));

        resultNum = Math.floor((right * results.length) / picked.length);
        if (resultNum === results.length) resultNum--;

        break;
      case "Mostly":
        function mostly(arr) {
          return arr
            .sort(
              (a, b) =>
                arr.filter((v) => v.choice === a.choice).length -
                arr.filter((v) => v.choice === b.choice).length
            )
            .pop();
        }
        resultNum = parseInt(mostly(picked).choice);
        break;
      default:
        break;
      // code block
    }
    console.log({ resultNum });
    QuizRef.current
      .getElementsByClassName("R")
      [resultNum].classList.add("active");
  };

  const Question = ({ text }) => {
    if (!matchIMG.test(text)) return <div>{text}</div>;
    return (
      <div
        className="qImg"
        style={{ backgroundImage: `url(${imgPath}${text})` }}
      >
        <span>{text}</span>
      </div>
    );
  };
  const Choice = ({ text, choice }) => {
    if (!matchIMG.test(text))
      return (
        <li onClick={answerChosen} data-choice={choice}>
          <span>{text}</span>
        </li>
      );
    return (
      <li
        className="cImg"
        style={{ backgroundImage: `url(${imgPath}${text})` }}
        onClick={answerChosen}
        data-choice={choice}
      >
        <span>{text}</span>
      </li>
    );
  };

  const init = () => {
    const mainQuiz = (
      <div className={className ? "quiz " + className : "quiz"} ref={QuizRef}>
        <div className="questions">
          <ol>
            {questions.map((Q, index) => (
              <li className={index === 0 ? "Q active" : "Q"}>
                <Question text={Q.question} />
                <ol>
                  {Q.choices.map((C, I) => (
                    <Choice choice={I} text={C} />
                  ))}
                </ol>
              </li>
            ))}
          </ol>
        </div>
        <div className="results">
          <ul>
            {results.map((R) => {
              const { title, image, copy } = R;
              const newCopy = copy.split(/\[|\]/).map((c, i) => {
                if (i % 2) return <span className={c} />;
                return c;
              });

              return (
                <li className="R">
                  <h3>{title}</h3>
                  {<div>{newCopy}</div>}
                  {image && <img src={image} alt={title + " image"} />}
                  <button onClick={resetQuiz}>Take Again?</button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );

    return mainQuiz;
  };

  return init();
}

export default MyQuiz;

import React from "react";
import { motion } from "framer-motion";
import { overlayMotion } from "../data/MotionVars";
import { Link } from "react-router-dom";

import { URLbase, getSection} from "../data/Sections";

import MyQuiz from "./MyQuiz";
import MyVideo from "./MyVideo";

import "../components/ActivityHolder.css"

const addActivity = (activity, store) => {
    switch(activity) {
        case 'quiz'  : return <MyQuiz  quiz={store.quiz} />;
        case 'video' : return <MyVideo videos={store.videos}/>;
        default: console.log("Activity issue");
      }
}

function ActivityHolder({match}){
    const {id, activity} = match.params
    const section = getSection(id);
    return(
        <motion.section {...overlayMotion} className="activityBG">
            <div className="activityHolder">
                {addActivity(activity, section)}
            </div>
            <Link className="backButton" to={`${URLbase}/${id}`}> Back </Link>
        </motion.section>
    )
};

export default ActivityHolder;
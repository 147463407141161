import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { sections, getSection, URLbase } from "../data/Sections";
import { avatarMotion, textMotion } from "../data/MotionVars";

import "../components/AvatarText.css";

function AvatarText({ match }){

    let Section = getSection(match.params.id);
    let storeNum = parseInt( Section.id );
    let nextSection = (storeNum > 1) ? storeNum - 1: sections.length;
    let prevSection = (storeNum < sections.length) ? storeNum + 1: 1;

    console.log({nextSection,prevSection, Section})

    const { title, id, videos, books, quiz, copy, game, avatar } = Section;

    return (
        <section className="avatarText">

            <motion.div {...textMotion} className="textHolder">
                <motion.div {...avatarMotion} >
                    {avatar   && <img className="Avatar" src={avatar} alt="MyAvatar"/>}
                </motion.div>
                <div className="title">{title}</div>
                
                <div className="copy">{ copy }</div>
                
                <div className="actionHolder">
                    {game   && <Link to={`${URLbase}/${id}/game/` }>Watch Videos</Link>}
                    {videos && <Link to={`${URLbase}/${id}/video/`}>Watch Videos</Link>}
                    {books  && <Link to={`${URLbase}/${id}/book/` }>Read Books</Link>}
                    {quiz   && <Link to={`${URLbase}/${id}/quiz/` }>Take Quiz</Link>}
                </div>

                <div className="nextPrev">
                        <Link className="next" to={`${URLbase}/${prevSection}`}><FontAwesomeIcon icon={faChevronRight} /></Link>
                        <Link className="prev" to={`${URLbase}/${nextSection}`}><FontAwesomeIcon icon={faChevronLeft} /></Link>
                </div>        
            </motion.div>
        
        </section>
    );
};

  
export default AvatarText;
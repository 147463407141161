import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {URLbase} from "./data/Sections";

import MySketchFab from "./components/MySketchFab";
import ActivityHolder from "./components/ActivityHolder";
import AvatarText from "./components/AvatarText";
import Menu from "./components/Menu";

import "./App.css";

function App() {
  console.clear();
  var location = useLocation();
  return (
    <>
      <MySketchFab model="b8f19addfe7e43978176a3c4e9822365" location={location} />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route component={ActivityHolder} path={`${URLbase}/:id/:activity/`} exact />
          <Route component={AvatarText}     path={`${URLbase}/:id/`} exact />
          <Route component={AvatarText}     path="/" />
        </Switch>
      </AnimatePresence>
      <Menu />
    </>
  );
}

export default App;

import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faTimes} from '@fortawesome/free-solid-svg-icons'

import {sections, URLbase} from "../data/Sections";

import "../components/Menu.css";

function Menu(){
    const [openMenu, setOpenMenu] = useState(false);
    return (
      <nav className={openMenu ? "MainNav open" : "MainNav"}>
        <div className="menu" onClick={() => setOpenMenu(true)}>
          <FontAwesomeIcon icon={faMapMarkedAlt} />
        </div>

        <div className="nav">
          <div className="close" onClick={() => setOpenMenu(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <div className="navHolder">
            <ul>
              {sections.map((i) => (
                <li key={"link-" + i.id}>
                  <NavLink
                    exact
                    to={`${URLbase}/${i.id}`}
                    onClick={() => setOpenMenu(false)}
                  >
                    {i.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    );
}
  
export default Menu;
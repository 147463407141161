import React, {useState} from "react";
import "../components/MyVideo.css"

function MyVideo({ videos }){
    let MainVid = videos[0];
    const [videoSRC, setVideoSRC] = useState(MainVid);
    let URL = `https://players.brightcove.net/6058004226001/default_default/index.html?videoId=${videoSRC}`
    return (
        <div className="MyVideo">
          <div className="vMain">
            <div>
              <iframe
                key={URL}
                title="myVideoPlayer"
                src={URL}
                allowFullScreen=""
                allow="encrypted-media"
              ></iframe>
            </div>
          </div>
          {videos.length > 1 && videos.map( vidID => (
            <img className="videoThumb" 
            onClick={()=>setVideoSRC(vidID)}
            src={`https://via.placeholder.com/160x90.png?text=${vidID}`}
            alt={`Video Thumbnail for:${vidID}`} />
          ))}
        </div>
    );
  };

  export default MyVideo;
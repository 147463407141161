import React from "react";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLinkedin, faChevronLeft} from '@fortawesome/free-solid-svg-icons'

const cameraSpeed = 1;
/*
const NotFound = {
  id: 6,
  title: "Page Not Found",
  copy: [
    "Welcome to Orange Valley Mall! This is, like, my favorite place to hang out—especially in the summer, when it gets super hot in California. The whole mall is air conditioned, and I always see my friends when I’m here. Want to look around with me? Come on! I’ll give you a tour"
  ],
  camera: {
    landscape:[[11.1676,-10.1261,27.1553],[10.3360,-7.8254,23.7991], cameraSpeed],
    portrait:[[11.1676,-10.1261,27.1553],[10.3360,-7.8254,23.7991], cameraSpeed],
  }
}
*/
const Home = {
  id: 1,
  title: "Welcome",
  avatar:"/img/welcome.png",
  camera: {
    landscape: [[0.6978, -9.2504, 15.6501], [1.2116, -2.091, 13.5432], cameraSpeed],
    portrait: [[1.2061, -10.6728, 16.2939], [1.8866, -2.4291, 13.5493], cameraSpeed],
  },
};
const Work = {
  id: 2,
  title: "Work",
  avatar:"/img/work.png",
  camera: {
    landscape: [[4.687, -3.4419, 8.5588], [3.6583, -0.8103, 7.4266], cameraSpeed],
    portrait: [[6.2779, -5.8079, 8.6286], [4.2102, -1.9849, 7.2979], cameraSpeed],
  },
};
const About = {
  id: 3,
  title: "About",
  avatar:"/img/about.png",
  camera: {
    landscape: [[4.287, -3.0763, 5.0871], [3.858, -2.112, 4.6467], cameraSpeed],
    portrait: [[2.5349, -5.3504, 5.5948], [2.3399, -1.8355, 4.1448], cameraSpeed],
  },
};
const Resume = {
  id: 4,
  title: "Resume",
  avatar:"/img/resume.png",
  camera: {
    landscape: [[-8.6921, -4.2471, 14.7626], [-7.8943, -1.0403, 11.3547], cameraSpeed],
    portrait: [[-8.079, -3.5507, 14.3528], [-6.0937, 1.9582, 8.8122], cameraSpeed],
  },
};
const Contact = {
  id: 5,
  title: "Contact",
  avatar:"/img/contact.png",
  camera: {
    landscape: [[-9.9299, -18.4697, 2.898], [-10.7342, -13.0768, -0.0193], cameraSpeed],
    portrait: [[-8.0964, -17.9755, 1.9142], [-9.5613, -13.6015, 0.3685], cameraSpeed],
  },
};
const Links = {
  id: 6,
  title: "Links",
  avatar:"/img/links.png",
  camera: {
    landscape: [[12.9551, 0.5572, 2.6098], [10.5389, 9.0868, 0.0352], cameraSpeed],
    portrait: [[12.9578, -2.7265, 2.7065], [12.7119, -1.0854, 2.3612], cameraSpeed],
  },
};
const Thanks = {
  id: 7,
  title: "Thanks",
  avatar:"/img/thanks.png",
  camera: {
    landscape:[[18.3570,-22.9886,5.2643],[17.1708,-14.7649,4.4098], cameraSpeed],
    portrait:[[18.4657,-22.9631,6.1675],[18.2178,-17.3319,4.8083], cameraSpeed],
  },
}

Home.copy = <p>Welcome to my playground! I created this site for me to experiment, showcase, and host some of my favorite projects. If you're reading this now I've given you a special sneak peek because this site isn't quite finished. I have a lot more to add and polish in the coming months.</p>
Work.copy = <p>My diverse range of projects span from functional prototypes, user interaction, and design. I'm a fan of using technology to simplify complex ideas and making technology easier on the user. . . .  especially if I’m the user! Check back soon.</p>
About.copy = <p>I'm a dog lover, maker, and coder. I've been called a technical innovator by coworkers, but I feel like a digital swiss army knife. I can have fun working on anything from running a full stack server on a Raspberry Pi to running vector artwork through my laser cutter.</p>
Resume.copy = <p>As a developer and prototyper, writing down my skills and experience can feel secondary to new projects or a portfolio. As I work through the best way to represent my career experience, feel free to download my latest <a href="/downloads/Brett_Williams-Resume.pdf" target="_blank" rel="noopener noreferrer">resume</a> or visit my <a href="https://www.linkedin.com/in/brett-williams-b435288/" target="_blank" rel="noopener noreferrer">LinkedIn</a> profile.</p>
Contact.copy =<p>Feel free to reach out and contact me. I love to 'nerd out' with like-minded techies and people passionate about innovation.<br/><a href="mailto:Brett@Brettwilliams.net">Brett@Brettwilliams.net</a></p>
Links.copy   =<p>The internet is an amazing source of tools, information, and ideas. Check back soon for a collection of my favorite resources.</p> 
Thanks.copy  = <p>I'm truly grateful for all the people, technology, applications, and services I’ve been able to utilize over the years; afterall, no one gets to where they are all by themselves.</p>


export const URLbase = ""
export const sections = [
  Home,
  Work,
  About,
  Resume,
  Contact,
  Links,
  Thanks
]

export const getSection =  (id) => {
  if (!id) return sections[0];
  return sections.find((s) => parseInt(id) === parseInt(s.id));
}